<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <h6 class="text-primary pb-2">
        Professional Information
        <CIcon
          v-if="!isModalView"
          name="cil-info"
          class="hinticon-multi-select mr-1"
          v-c-tooltip="
            ' See Profile Section below for Additional Details'
          "
        />
        <em
          v-if="!isModalView"
          class="fas fa-angle-double-down"
          style="cursor: pointer"
          @click="$parent.goToExpendableSection()"
        ></em>
      </h6>
      <div class="d-flex justify-content-end action-wrapper">
        <a class="px-2 btn" v-if="isEditable" @click="editProfessionalInfo">
          <i class="fas fa-pencil-alt" alt="Edit" />
        </a>
      </div>
    </div>
    <CRow v-if="!isStudent">
      <CCol>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Main Speciality</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ speciality }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Years in Speciality</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ yearsSpeciality }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Other Expertise</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ otherExpertise }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView" v-if="showMore">
          <CCol lg="4">
            <label class="text-gray">Highest Med Qual</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{
              highestQualificationName || "--"
            }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView" v-if="showMore">
          <CCol lg="4">
            <label class="text-gray">Country Obtained</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ highestQualCountry }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView" v-if="showMore">
          <CCol lg="4">
            <label class="text-gray">Year Obtained</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ highestQualYear }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView" v-if="showMore && !isCandidateRoleFromAccessToken">
          <CCol lg="4">
            <label class="text-gray">Suitable For</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ pqrlevel }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView" v-if="showMore">
          <CCol lg="4">
            <label class="text-gray">Working As</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ levelName }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView" v-if="showMore">
          <CCol lg="4">
            <label class="text-gray">Current Job Title</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ profile.current_job || "--" }}</span>
          </CCol>
        </CRow>
        <CRow class="profileView" v-if="showMore">
          <CCol lg="4">
            <label class="text-gray">Current License / Location</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ licenseLevel }}</span>
          </CCol>
        </CRow>
        <CRow class="profileView no-line" v-if="showMore && isNurse && isUKOrganisation">
          <CCol lg="4">
            <label class="text-gray">Current NHS Band</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ nhsBand||'--' }}</span>
          </CCol>
        </CRow>
        <CRow>
          <CCol
            class="more-less bottom-line text-center"
            @click="ShowMoreLess()"
          >
            <i
              class="fa fa-chevron-circle-up"
              title="Show less"
              v-if="showMore"
              aria-hidden="true"
            ></i>
            <i
              class="fa fa-chevron-circle-down"
              title="Show more"
              v-else
              aria-hidden="true"
            ></i>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <CRow v-if="isStudent && !studentCourse">
      <CCol>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Study Location</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ studentQualification.country_name || "--" }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Training Area</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ studentQualification.speciality_name || "--" }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Course level</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ studentQualification.qualification_name || "--" }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Course Start Date</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ formatDateMonthYear(studentQualification.course_start_date) || "--" }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Course End Date</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ formatDateMonthYear(studentQualification.predicted_completion_date) || "--" }}</span>
          </CCol>
        </CRow>

        <div class="profileView no-line" v-if="showMore">
          <CRow class="profileView">
            <CCol lg="4">
              <label class="text-gray">Is your course</label>
            </CCol>
            <CCol lg="8">
              <span class="text-black">{{ studentQualification.full_part_time || "--" }}</span>
            </CCol>
          </CRow>

          <CRow class="profileView">
            <CCol lg="4">
              <label class="text-gray">Course Mode</label>
            </CCol>
            <CCol lg="8">
              <span class="text-black">
                {{ studentCourse && studentCourse.distance_learning === true ? 'Yes' : (studentCourse && studentCourse.distance_learning === false ? 'No' : 'Classroom based') }}
              </span>
            </CCol>
          </CRow>

          <CRow class="profileView">
            <CCol lg="4">
              <label class="text-gray">Sponsored student</label>
            </CCol>
            <CCol lg="8">
              <span class="text-black">{{ isSponsored ? `Yes` : "No" }}</span>
            </CCol>
          </CRow>
          <CRow v-if="isSponsored" class="profileView">
            <CCol lg="4">
              <label class="text-gray">Sponsorship Start Date</label>
            </CCol>
            <CCol lg="8">
              <span class="text-black">{{ formatDateMonthYear(sponsoredDetails.start_date) }}</span>
            </CCol>
          </CRow>
          <CRow v-if="isSponsored" class="profileView">
            <CCol lg="4">
              <label class="text-gray">Sponsorship Expiry Date</label>
            </CCol>
            <CCol lg="8">
              <span class="text-black">{{ formatDateMonthYear(sponsoredDetails.end_date) }}</span>
            </CCol>
          </CRow>
        </div>
        <CRow>
          <CCol
            class="more-less bottom-line text-center"
            @click="ShowMoreLess()"
          >
            <i
              class="fa fa-chevron-circle-up"
              title="Show less"
              v-if="showMore"
              aria-hidden="true"
            ></i>
            <i
              class="fa fa-chevron-circle-down"
              title="Show more"
              v-else
              aria-hidden="true"
            ></i>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <CRow v-if="isStudent && studentCourse">
      <CCol>
        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Course Title</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ trainingCourse?.course_title || "--" }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Is your course</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ studentCourse.full_part_time || "--" }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Start Date</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ formatDateMonthYear(studentCourse.course_start_date) || "--" }}</span>
          </CCol>
        </CRow>

        <CRow class="profileView">
          <CCol lg="4">
            <label class="text-gray">Completion Date</label>
          </CCol>
          <CCol lg="8">
            <span class="text-black">{{ formatDateMonthYear(studentCourse.course_end_date) || "--" }}</span>
          </CCol>
        </CRow>

        <div class="profileView no-line" v-if="showMore">
          <CRow class="profileView">
            <CCol lg="4">
              <label class="text-gray">Course Mode</label>
            </CCol>
            <CCol lg="8">
              <span class="text-black">
                {{ studentCourse && studentCourse.distance_learning === true ? 'Yes' : (studentCourse && studentCourse.distance_learning === false ? 'No' : 'Classroom based') }}
              </span>
            </CCol>
          </CRow>

          <CRow class="profileView">
            <CCol lg="4">
              <label class="text-gray">Sponsored student</label>
            </CCol>
            <CCol lg="8">
              <span class="text-black">{{ isSponsored ? `Yes` : "No" }}</span>
            </CCol>
          </CRow>
          <CRow v-if="isSponsored" class="profileView">
            <CCol lg="4">
              <label class="text-gray">Sponsorship Start Date</label>
            </CCol>
            <CCol lg="8">
              <span class="text-black">{{ formatDateMonthYear(sponsoredDetails.start_date) }}</span>
            </CCol>
          </CRow>
          <CRow v-if="isSponsored" class="profileView">
            <CCol lg="4">
              <label class="text-gray">Sponsorship Expiry Date</label>
            </CCol>
            <CCol lg="8">
              <span class="text-black">{{ formatDateMonthYear(sponsoredDetails.end_date) }}</span>
            </CCol>
          </CRow>
        </div>
        <CRow>
          <CCol
            class="more-less bottom-line text-center"
            @click="ShowMoreLess()"
          >
            <i
              class="fa fa-chevron-circle-up"
              title="Show less"
              v-if="showMore"
              aria-hidden="true"
            ></i>
            <i
              class="fa fa-chevron-circle-down"
              title="Show more"
              v-else
              aria-hidden="true"
            ></i>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <ProfessionalInfoNurseEdit
      :candidateInfo="profile"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="ProfessionalInfoModalCallBack"
      v-if="EditModal.isShowPopup"
      :qualifications="qualifications"
    />
    <Modal
      v-if="splConfirmModal.isShowPopup"
      :modalTitle="splConfirmModal.modalTitle"
      :modalColor="splConfirmModal.modalColor"
      :modalContent="splConfirmModal.modalContent"
      :isShowPopup="splConfirmModal.isShowPopup"
      :buttons="splConfirmModal.buttons"
      :modalCallBack="splModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>
<script>
import { Role, getScope } from "@/helpers/helper";
import ProfessionalInfoNurseEdit from "./EditForm/ProfessionalInfoNurseEdit";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import moment from "moment";
import isJobSupplier from "@/mixins/isJobSupplier";

export default {
  mixins: [isJobSupplier],
  props: {
    profile: {
      type: Object,
      default: null,
    },
    nurseSpeciality: {
      type: Array,
      default: [],
    },
    specialInterest: {
      type: Array,
      default: [],
    },
    qualifications: {
      type: Array,
      default: () => [],
    },
    isEditable:{
      type: Boolean,
      default :false,
    },
    isModalView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProfessionalInfoNurseEdit,
    Modal,
  },
  data() {
    return {
      currentUserRole: getScope(),
      Role,
      EditModal: {
        isShowPopup: false,
      },
      splConfirmModal: {
        modalColor: "primary",
        modalTitle: "NOTIFICATION",
        modalContent: "Do you need to reset the Special Interest?",
        isShowPopup: false,
        buttons: ["No", "Yes"],
      },
      showMore: false,
    };
  },
  computed: {
    ...mapGetters([
      // "getCandidateSpecialInterest",
      "getLicense",
      "getCandidateNonMedicalQuals",
      "currentUserType",
      "isUKOrganisation",
      "isCandidateRoleFromAccessToken",
      "isEducationalInstituteOrg"
    ]),
    getCandidateSpecialInterest() {
      return (
        (this.profile?.candidate_specialities &&
          this.profile?.candidate_specialities) ||
        []
      );
    },
    mainSpeciality() {
      return this.nurseSpeciality.filter(({ main }) => main) || [];
    },
    otherSpeciality() {
      return this.nurseSpeciality.filter(({ is_current }) => !is_current) || [];
    },
    otherExpertise() {
      return (
        _(this.nurseSpeciality)
          .filter((speciality) => {
            return (
              speciality.main != true &&
              !speciality.other_special_interest &&
              !speciality.special_interest
            );
          })
          .map((val) => {
            return val.speciality
              ?  this.isNurse && val?.sub_speciality_id ? `${val.speciality?.speciality} - ${val?.sub_speciality?.sub_speciality}` : val.speciality?.speciality
              : val?.other_speciality;
          })
          .value()
          .join(", ") || "--"
      );
    },
    highestQualCountry() {
      return (
        (this.highestQualification.length &&
          this.highestQualification
            .map(({ country_name }) => country_name)
            .join()) ||
        "--"
      );
    },
    highestQualYear() {
      return (
        (this.highestQualification.length &&
          this.highestQualification
            .map(({ year_awarded }) => year_awarded)
            .join()) ||
        "--"
      );
    },
    speciality() {
      return (
        (this.mainSpeciality.length &&
          this.mainSpeciality
            .map(
              ({
                speciality,
                sub_speciality,
              }) => this.isNurse && sub_speciality?`${speciality?.speciality} - ${sub_speciality?.sub_speciality}`: speciality
            )
            .join()) ||
        this.profile?.speciality?.speciality ||
        "--"
      );
    },
    expertise() {
      return (
        (this.otherSpeciality.length &&
          this.otherSpeciality
            .map(({ speciality: { speciality } }) => speciality)
            .join()) ||
        "--"
      );
    },
    yearsSpeciality() {
      return (
        (this.mainSpeciality.length &&
          this.mainSpeciality
            .map(({ experience_in_years }) => experience_in_years)
            .join()) ||
        "--"
      );
    },
    specialInterestName() {
      return (
        (this.specialInterest.length &&
          this.specialInterest
            .map(({ special_interest, other_special_interest }) =>
              special_interest
                ? special_interest?.special_interest
                : other_special_interest
            )
            .join()) ||
        "--"
      );
    },
    firstQualification() {
      return (
        (this.profile?.qualifications &&
          this.profile?.qualifications.filter(
            ({ main }) =>
              (main && this.profile.qualifications.length === 1) ||
              (!main && this.profile.qualifications.length > 1)
          )) ||
        []
      );
    },
    firstQualificationName() {
      return (
        (this.firstQualification.length &&
          this.firstQualification
            .map(
              ({
                qualification_name,
                month_awarded,
                year_awarded,
                country_name,
              }) =>
                `${qualification_name}-${country_name}-${this.formatDate(
                  month_awarded,
                  year_awarded
                )}`
            )
            .join()) ||
        "--"
      );
    },
    highestQualification() {
      return (
        (this.profile?.qualifications &&
          this.profile.qualifications.length > 1 &&
          this.profile?.qualifications.filter(({ main }) => main)) ||
        (this.profile.qualifications.length === 1 &&
          this.profile?.qualifications) ||
        []
      );
    },
    highestQualificationName() {
      if (
        this.profile?.qualifications &&
        this.profile?.highest_qualification_name &&
        this.profile?.highest_qualification_name == "Other - not listed"
      ) {
        return _(this.profile?.qualifications)
          .filter((qualification) => {
            return (
              qualification?.main &&
              qualification?.qualification_name ==
                this.profile?.highest_qualification_name
            );
          })
          .map((qualification) => {
            return qualification?.other_qualification_name
              ? "Other - " + qualification?.other_qualification_name
              : null;
          })
          .value()
          .toString();
      } else {
        return (
          this.highestQualification.length &&
          this.highestQualification
            .map(
              ({
                qualification_name,
                description,
                qualification: { allow_subject_input },
              }) =>
                `${qualification_name}${
                  allow_subject_input && description
                    ? "(" + description + ")"
                    : ""
                }`
            )
            .join()
        );
      }
    },
    nonMedicalQualifications() {
      return (
        this.getCandidateNonMedicalQuals
          .map(({ non_medical_qual }) => non_medical_qual?.name)
          .join() || "--"
      );
    },
    candidateLevel() {
      return this.profile?.candidate_level?.candidate_level
        ? `${this.profile?.candidate_level?.candidate_level}${
            this.profile?.candidate_level?.description !==
            this.profile?.candidate_level?.candidate_level
              ? " - " + this.profile?.candidate_level?.description
              : ""
          }`
        : "--";
    },
    pqrlevel() {
      const pqr =
        this.profile.pqr_levels.filter(({ potential }) => !potential) || [];
      if(pqr.length){
         if(pqr[0]?.working_candidate_level){
            const level = pqr[0]?.working_candidate_level;
            const country_code = pqr[0]?.pqr_detail?.pqr_header?.country?.country_code;
            return country_code ? `${level}(${country_code})`:level;
          }
      }
      return "--";
    },
    isNurse() {
      return this.profile?.candidate_type_id
        ? this.profile?.candidate_type_id === 2
        : false;
    },
    nurseType() {
      return this.profile?.nurse_registration_type
        ? this.profile?.nurse_registration_type.filter(v=>v!="any_higherqualification_related_to_practice").map(v=>_.startCase(v)).join(", ") || "--"
        : "--";
    },
    licenseLevel() {
      return `${this.profile?.license_level_name || '--'} / ${this.profile?.licensing_country_name || '--'}`;
    },
    nhsBand(){
      return this.profile.band?.band_name || null
    },
    levelName(){
      return this.profile?.candidate_level_name || '--'
    },
    isStudent(){
      return this.profile.is_student || false;
    },
    studentQualification() {
      return this.profile?.qualifications?.[0] || {};
    },
    isSponsored() {
      return this.profile?.is_sponsored
    },
    sponsoredDetails() {
      return this.profile?.sponsored
    },
    studentCourse() {
      if (this.isEducationalInstituteOrg) {
        return this.profile?.student_course
      } 
      return this.profile?.qualifications?.[0] || {}
    },
    trainingCourse() {
      return this.profile?.student_course?.training_course
    },
  },
  methods: {
    ...mapActions([
      "updateCandidate",
      "fetchQualification",
      "deleteSplInterestWithoutToast",
      "fetchspecialInterest",
      "showToast",
      "fetchPQRLevels",
    ]),
    editProfessionalInfo() {
      this.EditModal.isShowPopup = true;
    },
    ProfessionalInfoModalCallBack(action, data, isSpecialityChanged) {
      this.EditModal.isShowPopup = false;
      if (action === true && data != null) {
        const { customer_uid } = this.profile;
        let appentAction = this.updateCandidate({ data, customer_uid });
        Promise.all([appentAction]).then(async (res) => {
          await this.fetchPQRLevels();
          if (isSpecialityChanged?.length) {
            this.fetchQualification();
            if (
              this.getCandidateSpecialInterest?.length &&
              isSpecialityChanged.some((val) => val == "speciality_id")
            ) {
              this.splConfirmModal.isShowPopup = true;
            }
          }
        });
      }
    },
    splModalCallBack(action) {
      this.splConfirmModal.isShowPopup = false;
      if (action == "Yes") {
        let appendAction = [];
        this.getCandidateSpecialInterest.forEach((val) => {
          if (val.special_interest_id)
            appendAction = this.deleteSplInterestWithoutToast(
              val.candidate_speciality_id
            );
        });
        Promise.all([appendAction]).then((res) => {
          this.fetchspecialInterest();
          this.showToast({
            class: "bg-success text-white",
            message: "Special Interest reset successful",
          });
        });
        this.$parent.goToSplInterestSection();
      }
    },
    formatDate(month_awarded, year_awarded) {
      if (month_awarded)
        return `${moment.monthsShort()[month_awarded - 1]} - ${year_awarded}`;
      return year_awarded || "";
    },
    ShowMoreLess() {
      this.showMore = !this.showMore;
    },
    formatDateMonthYear(date) {
      return date ? moment(date).format("DD MMM YYYY") : "--";
    },
  },
};
</script>
